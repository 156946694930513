import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Select/Button.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Common/Separator.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/TradeAndEarn/MarketsInfo/Boosted.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Partials/TradeAndEarn/MarketsInfo/Disqualified.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/App/Panel/Index.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "flex items-center gap-2" };
const _hoisted_2 = { key: 0 };
const _hoisted_3 = { key: 1 };
const _hoisted_4 = { class: "mt-4" };
export default /* @__PURE__ */ _defineComponent({
  __name: "MarketsInfo",
  setup(__props) {
    const { t } = useLang();
    const FilterList = {
      Boosted: "Boosted",
      Disqualified: "Disqualified"
    };
    const activeType = ref(FilterList.Boosted);
    return (_ctx, _cache) => {
      const _component_AppSelectButton = __nuxt_component_0;
      const _component_CommonSeparator = __nuxt_component_1;
      const _component_PartialsTradeAndEarnMarketsInfoBoosted = __nuxt_component_2;
      const _component_PartialsTradeAndEarnMarketsInfoDisqualified = __nuxt_component_3;
      const _component_AppPanel = __nuxt_component_4;
      return _openBlock(), _createBlock(_component_AppPanel, {
        title: _unref(t)("trade.marketsInformation"),
        "card-wrapper-class": "mt-6"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(FilterList), (filterType, index) => {
              return _openBlock(), _createElementBlock(_Fragment, {
                key: `market-information-type-${filterType}`
              }, [
                _createVNode(_component_AppSelectButton, {
                  modelValue: _unref(activeType),
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => _isRef(activeType) ? activeType.value = $event : null),
                  value: filterType
                }, {
                  default: _withCtx(({ active }) => [
                    _createElementVNode("span", {
                      class: _normalizeClass(["text-sm", [active ? "text-blue-500" : "text-gray-500"]])
                    }, [
                      filterType === FilterList.Boosted ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(t)("trade.boosted_markets")), 1)) : _createCommentVNode("", true),
                      filterType === FilterList.Disqualified ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_unref(t)("trade.disqualified_markets")), 1)) : _createCommentVNode("", true)
                    ], 2)
                  ]),
                  _: 2
                }, 1032, ["modelValue", "value"]),
                index !== Object.values(FilterList).length - 1 ? (_openBlock(), _createBlock(_component_CommonSeparator, { key: 0 })) : _createCommentVNode("", true)
              ], 64);
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_4, [
            _unref(activeType) === FilterList.Boosted ? (_openBlock(), _createBlock(_component_PartialsTradeAndEarnMarketsInfoBoosted, { key: 0 })) : _createCommentVNode("", true),
            _unref(activeType) === FilterList.Disqualified ? (_openBlock(), _createBlock(_component_PartialsTradeAndEarnMarketsInfoDisqualified, { key: 1 })) : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["title"]);
    };
  }
});
