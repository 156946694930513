import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/TradeAndEarn/PendingRewards/Epoch.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/HocLoading.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "relative" };
const _hoisted_2 = { class: "flex justify-between items-center mb-6" };
const _hoisted_3 = { class: "text-xl font-bold text-gray-200" };
const _hoisted_4 = { key: 0 };
const _hoisted_5 = { className: "text-gray-500" };
const _hoisted_6 = { class: "relative" };
import { Status, StatusType } from "@injectivelabs/utils";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  setup(__props) {
    const exchangeStore = useExchangeStore();
    const { $onError } = useNuxtApp();
    const { t } = useLang();
    const status = reactive(new Status(StatusType.Loading));
    const schedules = computed(
      () => useTradeReward().pendingPoolCampaignScheduleList.value || []
    );
    onMounted(() => {
      Promise.all([exchangeStore.fetchPendingTradeRewardPoints()]).catch($onError).finally(() => {
        status.setIdle();
      });
    });
    return (_ctx, _cache) => {
      const _component_PartialsTradeAndEarnPendingRewardsEpoch = __nuxt_component_0;
      const _component_AppHocLoading = __nuxt_component_1;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t("tradeAndEarn.pendingRewards")), 1)
          ]),
          _unref(schedules).length === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(t)("tradeAndEarn.emptyPendingRewards")), 1)
          ])) : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_AppHocLoading, { status: _unref(status) }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(schedules), (schedule, index) => {
                  return _openBlock(), _createBlock(_component_PartialsTradeAndEarnPendingRewardsEpoch, {
                    key: `pending-rewards-epoch-${index}`,
                    class: _normalizeClass(index > 0 ? "mt-12" : "mt-0"),
                    schedule,
                    index
                  }, null, 8, ["class", "schedule", "index"]);
                }), 128))
              ]),
              _: 1
            }, 8, ["status"])
          ])
        ])
      ]);
    };
  }
});
