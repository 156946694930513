import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Number/Emp.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Common/InfoTooltip.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/Common/Stats/Item.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/App/HocLoading.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/App/Panel/Index.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createSlots as _createSlots } from "vue";
const _hoisted_1 = { class: "text-gray-200" };
const _hoisted_2 = { class: "grid grid-cols-2 lg:grid-cols-12 gap-4 lg:gap-6" };
const _hoisted_3 = /* @__PURE__ */ _createElementVNode("span", null, "INJ", -1);
const _hoisted_4 = /* @__PURE__ */ _createElementVNode("span", null, "USD", -1);
const _hoisted_5 = { class: "flex items-center justify-center text-gray-450 text-xs" };
const _hoisted_6 = {
  key: 0,
  class: "flex flex-wrap justify-center"
};
const _hoisted_7 = /* @__PURE__ */ _createElementVNode("span", { class: "px-2 text-xl self-center" }, "/", -1);
const _hoisted_8 = {
  key: 1,
  class: "text-gray-450"
};
const _hoisted_9 = { class: "flex items-center justify-center text-xs text-gray-450 3xl:whitespace-nowrap -ml-2" };
const _hoisted_10 = /* @__PURE__ */ _createElementVNode("span", null, "INJ", -1);
const _hoisted_11 = { key: 1 };
const _hoisted_12 = /* @__PURE__ */ _createElementVNode("span", { class: "text-sm" }, "USD", -1);
const _hoisted_13 = { class: "flex items-center justify-center text-xs text-gray-450" };
import {
  BigNumberInBase,
  BigNumberInWei,
  Status,
  StatusType
} from "@injectivelabs/utils";
import { format } from "date-fns";
import { ZERO_IN_BASE } from "@injectivelabs/sdk-ui-ts";
import { cosmosSdkDecToBigNumber } from "@injectivelabs/sdk-ts";
import {
  UI_DEFAULT_MIN_DISPLAY_DECIMALS,
  DEFAULT_CAPPED_TRADE_AND_EARN_REWARDS,
  USDT_DECIMALS
} from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "CurrentEpoch",
  setup(__props) {
    const tokenStore = useTokenStore();
    const walletStore = useWalletStore();
    const exchangeStore = useExchangeStore();
    const { $onError } = useNuxtApp();
    const status = reactive(new Status(StatusType.Loading));
    const { rewardsCampaign, campaignInfo, poolCampaignScheduleList } = useTradeReward();
    const tradeRewardPoints = computed(() => {
      if (!exchangeStore.tradeRewardsPoints.length) {
        return ZERO_IN_BASE;
      }
      const [points] = exchangeStore.tradeRewardsPoints;
      if (!points) {
        return ZERO_IN_BASE;
      }
      return new BigNumberInBase(cosmosSdkDecToBigNumber(points));
    });
    const stakedAmount = computed(() => {
      if (!exchangeStore.feeDiscountAccountInfo) {
        return ZERO_IN_BASE;
      }
      if (!exchangeStore.feeDiscountAccountInfo.accountInfo) {
        return ZERO_IN_BASE;
      }
      return new BigNumberInBase(
        cosmosSdkDecToBigNumber(
          exchangeStore.feeDiscountAccountInfo.accountInfo.stakedAmount
        )
      );
    });
    const tradeRewardPointsFactored = computed(
      () => new BigNumberInWei(tradeRewardPoints.value).toBase(USDT_DECIMALS)
    );
    const totalTradeRewardPointsFactored = computed(
      () => new BigNumberInWei(totalTradeRewardPoints.value).toBase(USDT_DECIMALS)
    );
    const campaignDurationInSeconds = computed(() => {
      if (!campaignInfo.value) {
        return 0;
      }
      return new BigNumberInBase(
        campaignInfo.value.campaignDurationSeconds || 0
      ).toNumber();
    });
    const currentEpochStartTimestamp = computed(() => {
      if (!poolCampaignScheduleList.value) {
        return 0;
      }
      const [schedule] = poolCampaignScheduleList.value;
      if (!schedule) {
        return 0;
      }
      return new BigNumberInBase(schedule.startTimestamp).toNumber();
    });
    const epochCountdown = computed(
      () => format(
        (currentEpochStartTimestamp.value + campaignDurationInSeconds.value) * 1e3,
        "dd MMM HH:mm:ss"
      )
    );
    const injMaxCampaignRewards = computed(() => {
      if (!poolCampaignScheduleList.value) {
        return ZERO_IN_BASE;
      }
      const [schedule] = poolCampaignScheduleList.value;
      if (!schedule) {
        return ZERO_IN_BASE;
      }
      const [inj] = schedule.maxCampaignRewardsList;
      if (!inj) {
        return ZERO_IN_BASE;
      }
      return new BigNumberInBase(cosmosSdkDecToBigNumber(inj.amount || 0));
    });
    const injMaxCampaignRewardsInUsd = computed(
      () => injMaxCampaignRewards.value.multipliedBy(
        new BigNumberInBase(tokenStore.injUsdPrice)
      )
    );
    const totalTradeRewardPoints = computed(
      () => {
        var _a;
        return new BigNumberInBase(
          cosmosSdkDecToBigNumber(
            ((_a = rewardsCampaign.value) == null ? void 0 : _a.totalTradeRewardPoints) || 0
          )
        );
      }
    );
    const estimatedRewards = computed(() => {
      if (totalTradeRewardPoints.value.lte(0)) {
        return ZERO_IN_BASE;
      }
      const estRewards = tradeRewardPoints.value.dividedBy(totalTradeRewardPoints.value).times(injMaxCampaignRewards.value);
      if (estRewards.lte(DEFAULT_CAPPED_TRADE_AND_EARN_REWARDS)) {
        return estRewards;
      }
      if (stakedAmount.value.lte(DEFAULT_CAPPED_TRADE_AND_EARN_REWARDS)) {
        return new BigNumberInBase(DEFAULT_CAPPED_TRADE_AND_EARN_REWARDS);
      }
      return estRewards.gte(stakedAmount.value) ? stakedAmount.value : estRewards;
    });
    const estimatedRewardsInUsd = computed(
      () => estimatedRewards.value.multipliedBy(
        new BigNumberInBase(tokenStore.injUsdPrice)
      )
    );
    onMounted(() => {
      Promise.all([exchangeStore.fetchTradeRewardPoints()]).catch($onError).finally(() => {
        status.setIdle();
      });
    });
    return (_ctx, _cache) => {
      const _component_AppNumberEmp = __nuxt_component_0;
      const _component_CommonInfoTooltip = __nuxt_component_1;
      const _component_PartialsCommonStatsItem = __nuxt_component_2;
      const _component_AppHocLoading = __nuxt_component_3;
      const _component_AppPanel = __nuxt_component_4;
      return _openBlock(), _createBlock(_component_AppPanel, {
        title: _ctx.$t("Current Epoch"),
        "card-wrapper-class": "mt-6"
      }, _createSlots({
        default: _withCtx(() => [
          _createVNode(_component_AppHocLoading, { status: _unref(status) }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_PartialsCommonStatsItem, { class: "col-span-2 lg:col-span-4" }, {
                  value: _withCtx(() => [
                    _createVNode(_component_AppNumberEmp, {
                      number: _unref(injMaxCampaignRewards),
                      decimals: _unref(UI_DEFAULT_MIN_DISPLAY_DECIMALS)
                    }, {
                      default: _withCtx(() => [
                        _hoisted_3
                      ]),
                      _: 1
                    }, 8, ["number", "decimals"]),
                    _createVNode(_component_AppNumberEmp, {
                      class: "text-gray-450",
                      sm: "",
                      prefix: "\u2248",
                      number: _unref(injMaxCampaignRewardsInUsd),
                      decimals: _unref(UI_DEFAULT_MIN_DISPLAY_DECIMALS)
                    }, {
                      default: _withCtx(() => [
                        _hoisted_4
                      ]),
                      _: 1
                    }, 8, ["number", "decimals"])
                  ]),
                  title: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      _createTextVNode(_toDisplayString(_ctx.$t("max_campaign_rewards")) + " ", 1),
                      _createVNode(_component_CommonInfoTooltip, {
                        class: "ml-2 text-gray-450",
                        tooltip: _ctx.$t("max_campaign_rewards_tooltip")
                      }, null, 8, ["tooltip"])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_PartialsCommonStatsItem, { class: "col-span-2 lg:col-span-4" }, {
                  value: _withCtx(() => [
                    _unref(walletStore).isUserWalletConnected ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_AppNumberEmp, { number: _unref(tradeRewardPointsFactored) }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t("pts")), 1)
                        ]),
                        _: 1
                      }, 8, ["number"]),
                      _hoisted_7,
                      _createVNode(_component_AppNumberEmp, { number: _unref(totalTradeRewardPointsFactored) }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t("pts")), 1)
                        ]),
                        _: 1
                      }, 8, ["number"])
                    ])) : (_openBlock(), _createElementBlock("span", _hoisted_8, "\u2014"))
                  ]),
                  title: _withCtx(() => [
                    _createElementVNode("div", _hoisted_9, [
                      _createTextVNode(_toDisplayString(_ctx.$t("tradeAndEarn.myRewardPoints")) + " ", 1),
                      _createVNode(_component_CommonInfoTooltip, {
                        class: "ml-2 text-gray-450",
                        tooltip: _ctx.$t("tradeAndEarn.myRewardPoints_tooltip")
                      }, null, 8, ["tooltip"])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_PartialsCommonStatsItem, { class: "col-span-2 lg:col-span-4" }, {
                  value: _withCtx(() => [
                    _unref(walletStore).isUserWalletConnected ? (_openBlock(), _createBlock(_component_AppNumberEmp, {
                      key: 0,
                      number: _unref(estimatedRewards),
                      decimals: _unref(UI_DEFAULT_MIN_DISPLAY_DECIMALS)
                    }, {
                      default: _withCtx(() => [
                        _hoisted_10
                      ]),
                      _: 1
                    }, 8, ["number", "decimals"])) : (_openBlock(), _createElementBlock("span", _hoisted_11, "\u2014")),
                    _unref(walletStore).isUserWalletConnected ? (_openBlock(), _createBlock(_component_AppNumberEmp, {
                      key: 2,
                      sm: "",
                      class: "text-gray-450",
                      prefix: "\u2248",
                      number: _unref(estimatedRewardsInUsd),
                      decimals: _unref(UI_DEFAULT_MIN_DISPLAY_DECIMALS)
                    }, {
                      default: _withCtx(() => [
                        _hoisted_12
                      ]),
                      _: 1
                    }, 8, ["number", "decimals"])) : _createCommentVNode("", true)
                  ]),
                  title: _withCtx(() => [
                    _createElementVNode("div", _hoisted_13, [
                      _createTextVNode(_toDisplayString(_ctx.$t("est_rewards")) + " ", 1),
                      _createVNode(_component_CommonInfoTooltip, {
                        class: "ml-2 text-gray-450",
                        tooltip: _ctx.$t("est_rewards_tooltip", {
                          maxRewards: _unref(DEFAULT_CAPPED_TRADE_AND_EARN_REWARDS)
                        })
                      }, null, 8, ["tooltip"])
                    ])
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }, 8, ["status"])
        ]),
        _: 2
      }, [
        _unref(currentEpochStartTimestamp) > 0 ? {
          name: "title-context",
          fn: _withCtx(() => [
            _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t("tradeAndEarn.campaignEndingOn", { date: _unref(epochCountdown) })), 1)
          ]),
          key: "0"
        } : void 0
      ]), 1032, ["title"]);
    };
  }
});
