import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/InfoTooltip.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Common/Stats/Item.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "font-mono text-lg"
};
const _hoisted_2 = {
  key: 1,
  class: "text-xs font-normal"
};
const _hoisted_3 = { class: "flex items-center justify-center text-gray-450" };
export default /* @__PURE__ */ _defineComponent({
  __name: "Disqualified",
  setup(__props) {
    const spotStore = useSpotStore();
    const derivativeStore = useDerivativeStore();
    const disqualifiedMarkets = computed(
      () => [...derivativeStore.markets, ...spotStore.markets].filter(
        (m) => useTradeReward().disqualifiedMarketIdsList.value.includes(m.marketId)
      ).map((m) => m.ticker)
    );
    return (_ctx, _cache) => {
      const _component_CommonInfoTooltip = __nuxt_component_0;
      const _component_PartialsCommonStatsItem = __nuxt_component_1;
      return _openBlock(), _createBlock(_component_PartialsCommonStatsItem, null, {
        value: _withCtx(() => [
          _unref(disqualifiedMarkets).length > 0 ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_unref(disqualifiedMarkets).join(", ")), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t("trade.there_are_no_disqualified_markets_on_this_relayer")), 1))
        ]),
        title: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createTextVNode(_toDisplayString(_ctx.$t("trade.disqualified_markets")) + " ", 1),
            _createVNode(_component_CommonInfoTooltip, {
              class: "ml-2 text-gray-450",
              tooltip: _ctx.$t("trade.disqualified_markets_tooltip")
            }, null, 8, ["tooltip"])
          ])
        ]),
        _: 1
      });
    };
  }
});
