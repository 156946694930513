import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Number/Index.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "text-sm text-gray-450 ml-1" };
import { UI_DEFAULT_DISPLAY_DECIMALS } from "@/app/utils/constants";
import { getDecimalsBasedOnNumber } from "@/app/utils/helpers";
export default /* @__PURE__ */ _defineComponent({
  __name: "Emp",
  props: {
    sm: Boolean,
    number: {
      required: true,
      type: Object
    },
    prefix: {
      type: String,
      default: ""
    },
    decimals: {
      type: Number,
      default: UI_DEFAULT_DISPLAY_DECIMALS
    }
  },
  setup(__props) {
    const props = __props;
    const formattedNumberWithDecimals = computed(
      () => getDecimalsBasedOnNumber(props.number, props.decimals)
    );
    const classes = computed(() => {
      const result = ["flex", "items-end", "justify-center"];
      const formattedNumber = formattedNumberWithDecimals.value.number.toFixed(
        formattedNumberWithDecimals.value.decimals
      );
      if (formattedNumber.length > 12 + formattedNumberWithDecimals.value.decimals || props.sm) {
        result.push("text-sm");
      } else {
        result.push("text-xl");
      }
      return result.join(" ");
    });
    return (_ctx, _cache) => {
      const _component_AppNumber = __nuxt_component_0;
      return _openBlock(), _createBlock(_component_AppNumber, {
        prefix: __props.prefix,
        number: _unref(formattedNumberWithDecimals).number,
        decimals: _unref(formattedNumberWithDecimals).decimals,
        class: _normalizeClass(_unref(classes))
      }, {
        addon: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ]),
        _: 3
      }, 8, ["prefix", "number", "decimals", "class"]);
    };
  }
});
