import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/TradeAndEarn/CurrentEpoch.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/TradeAndEarn/PendingRewards/Index.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/TradeAndEarn/MarketsInfo.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/App/HocLoading.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "container" };
const _hoisted_2 = { class: "w-full mx-auto xl:w-4/5 relative" };
import { Status, StatusType } from "@injectivelabs/utils";
export default /* @__PURE__ */ _defineComponent({
  __name: "trade-and-earn",
  setup(__props) {
    const exchangeStore = useExchangeStore();
    const tokenStore = useTokenStore();
    const { $onError } = useNuxtApp();
    const status = reactive(new Status(StatusType.Loading));
    onWalletConnected(() => {
      status.setLoading();
      exchangeStore.initTradeAndEarn().then(() => {
        Promise.all([
          tokenStore.fetchInjUsdPrice(),
          exchangeStore.fetchParams(),
          exchangeStore.fetchTradingRewardsCampaign()
        ]).catch($onError).finally(() => {
          status.setIdle();
        });
      }).catch($onError);
    });
    onBeforeUnmount(() => {
      exchangeStore.reset();
    });
    return (_ctx, _cache) => {
      const _component_PartialsTradeAndEarnCurrentEpoch = __nuxt_component_0;
      const _component_PartialsTradeAndEarnPendingRewards = __nuxt_component_1;
      const _component_PartialsTradeAndEarnMarketsInfo = __nuxt_component_2;
      const _component_AppHocLoading = __nuxt_component_3;
      return _openBlock(), _createBlock(_component_AppHocLoading, {
        status: _unref(status),
        class: "h-full"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_PartialsTradeAndEarnCurrentEpoch, { class: "mt-6" }),
              _createVNode(_component_PartialsTradeAndEarnPendingRewards, { class: "mt-12" }),
              _createVNode(_component_PartialsTradeAndEarnMarketsInfo, { class: "mt-12" })
            ])
          ])
        ]),
        _: 1
      }, 8, ["status"]);
    };
  }
});
