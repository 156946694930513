import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Number/Emp.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Common/InfoTooltip.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/Common/Stats/Item.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/App/Panel/Index.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, createSlots as _createSlots } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "text-right text-sm mb-4"
};
const _hoisted_2 = { class: "grid grid-cols-2 lg:grid-cols-12 gap-4 lg:gap-6" };
const _hoisted_3 = /* @__PURE__ */ _createElementVNode("span", null, "INJ", -1);
const _hoisted_4 = /* @__PURE__ */ _createElementVNode("span", null, "USD", -1);
const _hoisted_5 = { class: "flex items-center justify-center text-gray-450 text-xs" };
const _hoisted_6 = {
  key: 0,
  class: "flex flex-wrap justify-center"
};
const _hoisted_7 = /* @__PURE__ */ _createElementVNode("span", { class: "px-2 text-xl self-center" }, "/", -1);
const _hoisted_8 = {
  key: 1,
  class: "text-gray-450"
};
const _hoisted_9 = { class: "flex items-center justify-center text-xs text-gray-450 3xl:whitespace-nowrap -ml-2" };
const _hoisted_10 = /* @__PURE__ */ _createElementVNode("span", null, "INJ", -1);
const _hoisted_11 = { key: 1 };
const _hoisted_12 = /* @__PURE__ */ _createElementVNode("span", { class: "text-sm" }, "USD", -1);
const _hoisted_13 = { class: "flex items-center justify-center text-gray-450" };
import { BigNumberInBase, BigNumberInWei } from "@injectivelabs/utils";
import { format } from "date-fns";
import { ZERO_IN_BASE } from "@injectivelabs/sdk-ui-ts";
import {
  cosmosSdkDecToBigNumber
} from "@injectivelabs/sdk-ts";
import {
  UI_DEFAULT_MIN_DISPLAY_DECIMALS,
  DEFAULT_CAPPED_TRADE_AND_EARN_REWARDS,
  USDT_DECIMALS
} from "@/app/utils/constants";
import { getHubUrl } from "@/app/utils/helpers";
export default /* @__PURE__ */ _defineComponent({
  __name: "Epoch",
  props: {
    index: {
      type: Number,
      required: true
    },
    schedule: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const walletStore = useWalletStore();
    const tokenStore = useTokenStore();
    const exchangeStore = useExchangeStore();
    const { rewardsCampaign } = useTradeReward();
    const hubUrl = `${getHubUrl()}/staking`;
    const stakedAmount = computed(() => {
      if (!exchangeStore.feeDiscountAccountInfo) {
        return ZERO_IN_BASE;
      }
      if (!exchangeStore.feeDiscountAccountInfo.accountInfo) {
        return ZERO_IN_BASE;
      }
      return new BigNumberInBase(
        cosmosSdkDecToBigNumber(
          exchangeStore.feeDiscountAccountInfo.accountInfo.stakedAmount
        )
      );
    });
    const vestingDurationInSeconds = computed(() => {
      if (!exchangeStore.params) {
        return 0;
      }
      if (!exchangeStore.params.tradingRewardsVestingDuration) {
        return 0;
      }
      return new BigNumberInBase(
        exchangeStore.params.tradingRewardsVestingDuration || 0
      ).toNumber();
    });
    const currentEpochStartTimestamp = computed(() => {
      if (!props.schedule) {
        return 0;
      }
      return new BigNumberInBase(props.schedule.startTimestamp).toNumber();
    });
    const pendingRewardsStartTimestamp = computed(() => {
      if (currentEpochStartTimestamp.value === 0) {
        return 0;
      }
      return new BigNumberInBase(currentEpochStartTimestamp.value).minus(vestingDurationInSeconds.value).toNumber();
    });
    const pendingRewardsCountdown = computed(() => {
      return format(
        (pendingRewardsStartTimestamp.value + vestingDurationInSeconds.value) * 1e3,
        "dd MMM HH:mm:ss"
      );
    });
    const injMaxPendingCampaignRewards = computed(() => {
      if (!props.schedule) {
        return ZERO_IN_BASE;
      }
      const [inj] = props.schedule.maxCampaignRewardsList;
      return new BigNumberInBase(cosmosSdkDecToBigNumber(inj.amount || 0));
    });
    const injMaxPendingCampaignRewardsInUsd = computed(() => {
      return injMaxPendingCampaignRewards.value.multipliedBy(
        new BigNumberInBase(tokenStore.injUsdPrice)
      );
    });
    const pendingTradeRewardPoints = computed(() => {
      if (exchangeStore.pendingTradeRewardsPoints.length === 0) {
        return ZERO_IN_BASE;
      }
      return new BigNumberInBase(
        cosmosSdkDecToBigNumber(
          exchangeStore.pendingTradeRewardsPoints[props.index] || 0
        )
      );
    });
    const pendingTradeRewardPointsFactored = computed(() => {
      return new BigNumberInWei(pendingTradeRewardPoints.value).toBase(
        USDT_DECIMALS
      );
    });
    const totalPendingTradeRewardPoints = computed(() => {
      if (!rewardsCampaign.value) {
        return ZERO_IN_BASE;
      }
      const pointsList = rewardsCampaign.value.pendingTotalTradeRewardPointsList;
      if (pointsList.length === 0) {
        return ZERO_IN_BASE;
      }
      return new BigNumberInBase(
        cosmosSdkDecToBigNumber(pointsList[props.index] || 0)
      );
    });
    const totalPendingTradeRewardPointsFactored = computed(() => {
      return new BigNumberInWei(totalPendingTradeRewardPoints.value).toBase(
        USDT_DECIMALS
      );
    });
    const pendingEstimatedRewards = computed(() => {
      if (totalPendingTradeRewardPoints.value.lte(0)) {
        return ZERO_IN_BASE;
      }
      if (pendingTradeRewardPoints.value.lte(0)) {
        return ZERO_IN_BASE;
      }
      return pendingTradeRewardPoints.value.dividedBy(totalPendingTradeRewardPoints.value).times(injMaxPendingCampaignRewards.value);
    });
    const pendingEstimatedRewardsCapped = computed(() => {
      if (pendingEstimatedRewards.value.lte(DEFAULT_CAPPED_TRADE_AND_EARN_REWARDS)) {
        return pendingEstimatedRewards.value;
      }
      if (stakedAmount.value.lte(DEFAULT_CAPPED_TRADE_AND_EARN_REWARDS)) {
        return new BigNumberInBase(DEFAULT_CAPPED_TRADE_AND_EARN_REWARDS);
      }
      return pendingEstimatedRewards.value.gte(stakedAmount.value) ? stakedAmount.value : pendingEstimatedRewards.value;
    });
    const pendingEstimatedRewardsCappedInUsd = computed(
      () => pendingEstimatedRewardsCapped.value.multipliedBy(
        new BigNumberInBase(tokenStore.injUsdPrice)
      )
    );
    return (_ctx, _cache) => {
      const _component_AppNumberEmp = __nuxt_component_0;
      const _component_CommonInfoTooltip = __nuxt_component_1;
      const _component_PartialsCommonStatsItem = __nuxt_component_2;
      const _component_AppPanel = __nuxt_component_3;
      return _openBlock(), _createBlock(_component_AppPanel, null, {
        default: _withCtx(() => [
          _unref(pendingRewardsStartTimestamp) > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t("tradeAndEarn.campaignAsOf", { date: _unref(pendingRewardsCountdown) })), 1)) : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_PartialsCommonStatsItem, { class: "col-span-2 lg:col-span-4" }, {
              value: _withCtx(() => [
                _createVNode(_component_AppNumberEmp, {
                  number: _unref(injMaxPendingCampaignRewards),
                  decimals: _unref(UI_DEFAULT_MIN_DISPLAY_DECIMALS)
                }, {
                  default: _withCtx(() => [
                    _hoisted_3
                  ]),
                  _: 1
                }, 8, ["number", "decimals"]),
                _createVNode(_component_AppNumberEmp, {
                  sm: "",
                  class: "text-gray-450",
                  prefix: "\u2248",
                  number: _unref(injMaxPendingCampaignRewardsInUsd),
                  decimals: _unref(UI_DEFAULT_MIN_DISPLAY_DECIMALS)
                }, {
                  default: _withCtx(() => [
                    _hoisted_4
                  ]),
                  _: 1
                }, 8, ["number", "decimals"])
              ]),
              title: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createTextVNode(_toDisplayString(_ctx.$t("tradeAndEarn.pending_max_campaign_rewards")) + " ", 1),
                  _createVNode(_component_CommonInfoTooltip, {
                    class: "ml-2 text-gray-450",
                    tooltip: _ctx.$t("tradeAndEarn.pending_max_campaign_rewards_tooltip")
                  }, null, 8, ["tooltip"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_PartialsCommonStatsItem, { class: "col-span-2 lg:col-span-4" }, {
              value: _withCtx(() => [
                _unref(walletStore).isUserWalletConnected ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_AppNumberEmp, { number: _unref(pendingTradeRewardPointsFactored) }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t("pts")), 1)
                    ]),
                    _: 1
                  }, 8, ["number"]),
                  _hoisted_7,
                  _createVNode(_component_AppNumberEmp, { number: _unref(totalPendingTradeRewardPointsFactored) }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t("pts")), 1)
                    ]),
                    _: 1
                  }, 8, ["number"])
                ])) : (_openBlock(), _createElementBlock("span", _hoisted_8, "\u2014"))
              ]),
              title: _withCtx(() => [
                _createElementVNode("div", _hoisted_9, [
                  _createTextVNode(_toDisplayString(_ctx.$t("tradeAndEarn.myRewardPoints")) + " ", 1),
                  _createVNode(_component_CommonInfoTooltip, {
                    class: "ml-2 text-gray-450",
                    tooltip: _ctx.$t("tradeAndEarn.myRewardPoints_tooltip")
                  }, null, 8, ["tooltip"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_PartialsCommonStatsItem, { class: "col-span-2 lg:col-span-4" }, _createSlots({
              value: _withCtx(() => [
                _unref(walletStore).isUserWalletConnected ? (_openBlock(), _createBlock(_component_AppNumberEmp, {
                  key: 0,
                  number: _unref(pendingEstimatedRewardsCapped),
                  decimals: _unref(UI_DEFAULT_MIN_DISPLAY_DECIMALS)
                }, {
                  default: _withCtx(() => [
                    _hoisted_10
                  ]),
                  _: 1
                }, 8, ["number", "decimals"])) : (_openBlock(), _createElementBlock("span", _hoisted_11, "\u2014")),
                _unref(walletStore).isUserWalletConnected ? (_openBlock(), _createBlock(_component_AppNumberEmp, {
                  key: 2,
                  sm: "",
                  class: "text-gray-450",
                  prefix: "\u2248",
                  number: _unref(pendingEstimatedRewardsCappedInUsd),
                  decimals: _unref(UI_DEFAULT_MIN_DISPLAY_DECIMALS)
                }, {
                  default: _withCtx(() => [
                    _hoisted_12
                  ]),
                  _: 1
                }, 8, ["number", "decimals"])) : _createCommentVNode("", true)
              ]),
              title: _withCtx(() => [
                _createElementVNode("div", _hoisted_13, [
                  _createTextVNode(_toDisplayString(_ctx.$t("tradeAndEarn.est_rewards_stake")) + " ", 1),
                  _createVNode(_component_CommonInfoTooltip, {
                    class: "ml-2 text-gray-450",
                    tooltip: _ctx.$t("tradeAndEarn.est_rewards_stake_tooltip", {
                      maxRewards: _unref(DEFAULT_CAPPED_TRADE_AND_EARN_REWARDS)
                    })
                  }, null, 8, ["tooltip"])
                ])
              ]),
              _: 2
            }, [
              _unref(pendingEstimatedRewards).gt(0) && _unref(pendingEstimatedRewardsCapped).lte(_unref(pendingEstimatedRewards)) ? {
                name: "context",
                fn: _withCtx(() => [
                  _unref(walletStore).isUserWalletConnected ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: hubUrl,
                    class: "text-blue-500 flex justify-center",
                    target: "_blank"
                  }, [
                    _createTextVNode(_toDisplayString(_ctx.$t("stake_more")) + " ", 1),
                    _createVNode(_component_CommonInfoTooltip, {
                      class: "ml-2 text-gray-450",
                      tooltip: _ctx.$t("tradeAndEarn.stake_total_to_receive_full_amount", {
                        total: _unref(pendingEstimatedRewards).toFormat(2)
                      })
                    }, null, 8, ["tooltip"])
                  ])) : _createCommentVNode("", true)
                ]),
                key: "0"
              } : void 0
            ]), 1024)
          ])
        ]),
        _: 1
      });
    };
  }
});
