import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/TextInfo.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Common/InfoTooltip.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/Common/Stats/Item.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "flex justify-between text-xs w-full mx-auto" };
const _hoisted_2 = { class: "flex-1 px-4 lg:px-6" };
const _hoisted_3 = { class: "text-gray-200 text-center font-semibold" };
const _hoisted_4 = { class: "text-white font-mono" };
const _hoisted_5 = { class: "text-sm text-gray-350 font-sans" };
const _hoisted_6 = { class: "text-sm text-gray-350 font-sans" };
const _hoisted_7 = { class: "flex-1 px-4 lg:px-12" };
const _hoisted_8 = { class: "text-gray-200 text-center font-semibold" };
const _hoisted_9 = { class: "text-white font-mono" };
const _hoisted_10 = { class: "text-sm text-gray-350 font-sans" };
const _hoisted_11 = { class: "text-sm text-gray-350 font-sans" };
const _hoisted_12 = { class: "flex items-center justify-center text-gray-450 text-xs" };
import { cosmosSdkDecToBigNumber } from "@injectivelabs/sdk-ts";
import { MARKETS_SLUGS } from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "Boosted",
  setup(__props) {
    const spotStore = useSpotStore();
    const derivativeStore = useDerivativeStore();
    const {
      spotBoostedMarketIdList,
      disqualifiedMarketIdsList,
      spotBoostedMultiplierList,
      derivativeBoostedMarketIdList,
      derivativeBoostedMultiplierList
    } = useTradeReward();
    const derivativeBoostedMarkets = computed(() => {
      const derivativeMarketIds = derivativeBoostedMarketIdList.value;
      const derivativeMarketsBoosts = derivativeBoostedMultiplierList.value;
      const disqualifiedMarketIds = disqualifiedMarketIdsList.value;
      const derivativeMarketsTickerBasedOnIds = derivativeStore.markets.filter(
        (derivativeMarket) => derivativeMarketIds.includes(derivativeMarket.marketId)
      ).filter(
        (derivativeMarket) => MARKETS_SLUGS.futures.includes(derivativeMarket.slug)
      ).sort(
        (a, b) => derivativeMarketIds.indexOf(a.marketId) - derivativeMarketIds.indexOf(b.marketId)
      ).map((m) => ({
        ticker: m.ticker,
        slug: m.slug,
        index: derivativeMarketIds.indexOf(m.marketId)
      }));
      const derivatives = derivativeMarketsTickerBasedOnIds.reduce(
        (records, market) => {
          return [
            ...records,
            {
              ...market,
              makerPointsMultiplier: cosmosSdkDecToBigNumber(
                derivativeMarketsBoosts[market.index].makerPointsMultiplier
              ).toFixed(),
              takerPointsMultiplier: cosmosSdkDecToBigNumber(
                derivativeMarketsBoosts[market.index].takerPointsMultiplier
              ).toFixed()
            }
          ];
        },
        []
      );
      const nonBoostedDerivatives = [...derivativeStore.markets].filter(
        (derivative) => !derivativeMarketIds.includes(derivative.marketId) && !disqualifiedMarketIds.includes(derivative.marketId)
      ).map((m) => ({ ticker: m.ticker, slug: m.slug })).reduce((records, market) => {
        return [
          ...records,
          {
            ...market,
            makerPointsMultiplier: "1",
            takerPointsMultiplier: "1"
          }
        ];
      }, []);
      return [...derivatives, ...nonBoostedDerivatives].sort(
        (a, b) => MARKETS_SLUGS.futures.indexOf(a.slug) - MARKETS_SLUGS.futures.indexOf(b.slug)
      );
    });
    const spotBoostedMarkets = computed(() => {
      const disqualifiedMarketIds = disqualifiedMarketIdsList.value;
      const spotMarketIds = spotBoostedMarketIdList.value;
      const spotMarketsBoosts = spotBoostedMultiplierList.value;
      const spotMarketsTickerBasedOnIds = spotStore.markets.filter((spotMarket) => spotMarketIds.includes(spotMarket.marketId)).filter((spot2) => MARKETS_SLUGS.spot.includes(spot2.slug)).sort(
        (a, b) => spotMarketIds.indexOf(a.marketId) - spotMarketIds.indexOf(b.marketId)
      ).map((m) => ({
        ticker: m.ticker,
        slug: m.slug,
        index: spotMarketIds.indexOf(m.marketId)
      }));
      const spot = spotMarketsTickerBasedOnIds.reduce((records, market) => {
        return [
          ...records,
          {
            ...market,
            makerPointsMultiplier: cosmosSdkDecToBigNumber(
              spotMarketsBoosts[market.index].makerPointsMultiplier
            ).toFixed(),
            takerPointsMultiplier: cosmosSdkDecToBigNumber(
              spotMarketsBoosts[market.index].takerPointsMultiplier
            ).toFixed()
          }
        ];
      }, []);
      const nonBoostedSpot = [...spotStore.markets].filter(
        (spotMarket) => !spotMarketIds.includes(spotMarket.marketId) && !disqualifiedMarketIds.includes(spotMarket.marketId)
      ).map((m) => ({ ticker: m.ticker, slug: m.slug })).reduce((records, market) => {
        return [
          ...records,
          {
            ...market,
            makerPointsMultiplier: "1",
            takerPointsMultiplier: "1"
          }
        ];
      }, []);
      return [...spot, ...nonBoostedSpot].sort(
        (a, b) => MARKETS_SLUGS.spot.indexOf(a.slug) - MARKETS_SLUGS.spot.indexOf(b.slug)
      );
    });
    return (_ctx, _cache) => {
      const _component_CommonTextInfo = __nuxt_component_0;
      const _component_CommonInfoTooltip = __nuxt_component_1;
      const _component_PartialsCommonStatsItem = __nuxt_component_2;
      return _openBlock(), _createBlock(_component_PartialsCommonStatsItem, null, {
        title: _withCtx(() => [
          _createElementVNode("div", _hoisted_12, [
            _createTextVNode(_toDisplayString(_ctx.$t("trade.boosted_markets")) + " ", 1),
            _createVNode(_component_CommonInfoTooltip, {
              class: "ml-2 text-gray-450",
              tooltip: _ctx.$t("trade.boosted_markets_tooltip")
            }, null, 8, ["tooltip"])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("trade.derivatives")), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(derivativeBoostedMarkets), (derivative) => {
                return _openBlock(), _createBlock(_component_CommonTextInfo, {
                  key: `derivative-${derivative.ticker}`,
                  title: derivative.ticker,
                  class: "mt-1 text-gray-550",
                  sm: ""
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", _hoisted_4, [
                      _createElementVNode("span", null, [
                        _createTextVNode(_toDisplayString(derivative.makerPointsMultiplier) + "x ", 1),
                        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("maker_points_mul")), 1),
                        _createTextVNode(" / " + _toDisplayString(derivative.takerPointsMultiplier) + "x ", 1),
                        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("taker_points_mul")), 1)
                      ])
                    ])
                  ]),
                  _: 2
                }, 1032, ["title"]);
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("trade.spot")), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(spotBoostedMarkets), (spot) => {
                return _openBlock(), _createBlock(_component_CommonTextInfo, {
                  key: `spot-${spot.ticker}`,
                  title: spot.ticker,
                  class: "mt-1 text-gray-550",
                  sm: ""
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", _hoisted_9, [
                      _createElementVNode("span", null, [
                        _createTextVNode(_toDisplayString(spot.makerPointsMultiplier) + "x ", 1),
                        _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("maker_points_mul")), 1),
                        _createTextVNode(" / " + _toDisplayString(spot.takerPointsMultiplier) + "x ", 1),
                        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("taker_points_mul")), 1)
                      ])
                    ])
                  ]),
                  _: 2
                }, 1032, ["title"]);
              }), 128))
            ])
          ])
        ]),
        _: 1
      });
    };
  }
});
